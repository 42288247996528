<template>
  <div class="counterspell">
    <h2>Tappo due isole..</h2>
    <base-button link :to="'/home'">L'hai fatto</base-button>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

.counterspell {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
</style>
