import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      lastGlobalRankingsFetch: null,
      globalRankings: [],
      lastLeagueRankingsFetch: null,
      leagueRankings: [],
      lastEventRankingsFetch: null,
      eventRankings: [],
      lastEventIdRankings: null,
      lastLeagueIdRankings: null,
    };
  },
  mutations,
  actions,
  getters,
};
