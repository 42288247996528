<template>
  <div>
    <base-dialog :show="!!error" title="Nessun match trovato" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-swipe v-if="currentRound">
      <div class="panel">
        <div class="container">
          <base-swipe-text :side="'left'" :swipeText="leftPanelText"></base-swipe-text>
          <div class="players">
            <div class="player">
              <div class="overlay" @click="addWinP1" v-if="!ended">+</div>
              <div class="points">{{ p1Wins }}</div>
              <div class="overlay-bottom" @click="removeWinP1" v-if="!ended">-</div>
              <h4>{{nameP1}} {{lastnameP1}}</h4>
            </div>
            <div class="player">
              <div class="overlay" @click="addWinP2" v-if="!ended">+</div>
              <div class="points">{{ p2Wins }}</div>
              <div class="overlay-bottom" @click="removeWinP2" v-if="!ended">-</div>
              <h4>{{nameP2}} {{lastnameP2}}</h4>
            </div>
          </div>
          <base-button class="btn-send" @click="sendScore" v-if="!ended">Invia Punteggio</base-button>
          <div v-else><h4>Punteggio confermato</h4></div>
        </div>
      </div>

      <div class="panel">
        <div class="container" v-if="getCurrentRound.previousRoundRankings">
          <ranked-list :players="getCurrentRound.previousRoundRankings"/>
        </div>
        <div class="container" v-else>
          <h4>La classifica verrà mostrata una volta terminato il turno!</h4>
        </div>
      </div>
      
    </base-swipe>
  </div>
</template>

<script>
import RankedList from '@/components/players/RankedList.vue';
import BaseSwipe from '@/components/layout/BaseSwipe.vue'
import BaseSwipeText from '@/components/layout/BaseSwipeText.vue'
import { mapGetters } from 'vuex';

export default {
  components: {
    RankedList,
    BaseSwipe,
    BaseSwipeText
  },
  data() {
    return {
      currentRound: null,
      p1Wins: 0,
      p2Wins: 0,
      error: null,
      leftPanelText: 'swipe → per la classifica del turno precedente',
    };
  },
  computed: {
    ...mapGetters('events', ['getCurrentRound']),
    swipeText(){
      return "swipe → per la classifica del turno precedente"
    },
    nameP1(){
      return this.currentRound ? this.currentRound.nameP1 : ""
    },
    nameP2(){
      return this.currentRound ? this.currentRound.nameP2 : ""
    },
    lastnameP1(){
      return this.currentRound ? this.currentRound.surnameP1 : ""
    },
    lastnameP2(){
      return this.currentRound ? this.currentRound.surnameP2 : ""
    },
    ended(){
      return this.currentRound ? this.currentRound.ended : true
    },
  },
  methods: {
    handleError() {
      this.error = null;
      this.$router.replace('/my-events')
    },
    async sendScore() {
      const actionPayload = {
        roundId: this.currentRound.id,
        p1Wins: this.p1Wins,
        p2Wins: this.p2Wins,
      };
      try {
        await this.$store.dispatch('events/confirmScore', {
          round: actionPayload,
        });
        this.$router.replace('/leagues/my-subs')
      } catch (err) {
        this.error = err.message || 'Il server risulta irraggiungibile..';
      }
    },
    async loadCurrentRound(){
      try {
        await this.$store.dispatch('events/findCurrentRound');
      } catch (err) {
        this.error = err.message || 'Il server risulta irraggiungibile..';
      } finally {
        this.setCurrentRound()
      }
    },
    setCurrentRound(){
      this.currentRound= this.getCurrentRound
      this.p1Wins= this.currentRound ? this.currentRound.p1Wins : 0
      this.p2Wins= this.currentRound ? this.currentRound.p2Wins : 0
    },
    addWinP1(){
      if(this.p1Wins<2 && (this.p1Wins+this.p2Wins<3)){
        this.p1Wins++;
      }
    },
    addWinP2(){
      if(this.p2Wins<2 && (this.p1Wins+this.p2Wins<3)){
        this.p2Wins++;
      }
    },
    removeWinP1(){
      if(this.p1Wins>0){
        this.p1Wins--;
      }
    },
    removeWinP2(){
      if(this.p2Wins>0){
        this.p2Wins--;
      }
    }
  },
  created() {
    this.loadCurrentRound()
  },
};
</script>

<style scoped>

.panel {
  min-width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.players {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 6%;
  width: 100%;
  height: 100%;
}
.player {
  margin-top: 2rem;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.4rem;
}
.points {
  width: 100%;
  height: 100%;
  font-size: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
h4 {
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;
}
.btn-send {
  margin-top: 2rem;
  align-self: center;
  width: 70%;
}

.overlay {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  position: absolute;
}

.overlay-bottom {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: 2rem;
}
.overlay{
  top: 0;
  font-size: 1.5rem;
  align-items: flex-start;
}
.overlay-bottom{
  top: 40%;
  font-size: 2rem;
  align-items: flex-end;
}

@media(min-width: 41rem){
  .overlay,
  .overlay-bottom{
    margin: 1rem auto;
  }
  .overlay{
    font-size: 4rem;
  }
  .overlay-bottom{
    font-size: 6rem;
  }
}

</style>
