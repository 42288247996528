import {createStore} from 'vuex'

import authModule from './modules/auth';
import eventsModule from './modules/events';
import playersModule from './modules/players';
import leaguesModule from './modules/leagues';
import subsModule from './modules/subs';
import rankingsModule from './modules/rankings';

const store= createStore({
    modules: {
        auth: authModule,
        events: eventsModule,
        players: playersModule,
        leagues: leaguesModule,
        subs: subsModule,
        rankings: rankingsModule,
    },
})

export const endpoint = 'https://ea9bl03u93.execute-api.eu-south-1.amazonaws.com/Prod';

export default store;