<template>
  <header>
    <nav class="navbar">
      <div class="logo">
        <router-link link :to="'/home'"><h1>PL</h1></router-link>
      </div>
      <ul class="nav-links" :class="{ active: isNavActive }">
        <li>
          <router-link link @click="toggleHamburger" :to="'/home'">Home</router-link>
        </li>
        <li>
          <router-link link @click="toggleHamburger" :to="'/counter'">Segnapunti</router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link link @click="toggleHamburger" :to="'/leagues/new'">Crea Lega</router-link>
        </li>
        <li>
          <router-link link @click="toggleHamburger" :to="'/leagues'">Leghe</router-link>
        </li>
        <li>
          <router-link link @click="toggleHamburger" :to="'/rankings'">Classifica Giocatori</router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link link @click="toggleHamburger" :to="'/leagues/my-subs'">Le mie iscrizioni</router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link link @click="toggleHamburger" :to="'/my-events'">I miei tornei</router-link>
        </li>
        <li v-if="isAuthenticated">
          <router-link link @click="toggleHamburger" :to="'/current'">Match corrente</router-link>
        </li>
        <li v-if="!isAuthenticated">
          <router-link link @click="toggleHamburger" :to="'/auth'">Accedi</router-link>
        </li>
        <li v-else>
          <router-link link @click="logout" :to="'/home'">Esci</router-link>
        </li>
      </ul>
      <div class="hamburger" @click="toggleHamburger" :class="{ active: isHamburgerActive }">
        <span></span>
        <span></span>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isNavActive: false,
      isHamburgerActive: false,
      isAnimating: false, // Flag per controllare l'animazione
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.toggleHamburger();
    },
    toggleHamburger() {
      if (this.isAnimating) return; // Se è già in animazione, esci dalla funzione
      this.isAnimating = true; // Inizia l'animazione

      // Cambia lo stato attivo del menu
      this.isNavActive = !this.isNavActive;
      this.isHamburgerActive = !this.isHamburgerActive; // Cambia lo stato attivo dell'hamburger
      
      // Aspetta il termine dell'animazione
      setTimeout(() => {
        this.isAnimating = false; // Resetta il flag al termine dell'animazione
      }, 600); // Cambiato a 600 ms per rallentare l'animazione
    },
  },
};
</script>

<style scoped>
/* Header and Navbar Styles */
header {
  background-color: #1c1c1c;
  border-bottom: 2px solid #333;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: relative;
  z-index: 1002;
}

.logo h1 {
  font-size: 1.5rem;
  color: #f5f5f5;
}

/* Full-screen Nav Links */
.nav-links {
  list-style: none;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.6s ease, opacity 0.6s ease;
  z-index: 1001;
}

.nav-links.active {
  transform: translateY(0);
  opacity: 1;
}

.nav-links li {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.nav-links.active li {
  opacity: 1;
  transform: translateY(0);
}

.nav-links li a {
  color: #ffffff;
  font-size: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #ff9800;
}

/* Hamburger Menu */
.hamburger {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  z-index: 1003;
}

.hamburger span {
  width: 20px;
  height: 1px;
  background-color: #f5f5f5;
  transition: transform 0.3s ease;
}

.hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(4px);
}

.hamburger.active span:nth-child(2) {
  transform: rotate(-45deg) translate(4px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links li a {
    font-size: 1.5rem;
  }
}
</style>
